<template lang="pug">
.box-week-plan
  h1 Plan de la semana
  h2 Plan de comidas
  .week Junio 28, 2021 - Julio 5, 2021
</template>
<script>
export default {
  name: "box-week",
};
</script>
<style lang="scss" scoped>
.box-week-plan {
  h1,
  .week {
    @apply text-xl font-semibold text-gray-400 uppercase pb-4;
  }

  h2 {
    @apply text-4xl font-semibold pb-4 pt-8;
  }

  .week {
    @apply text-base;
  }
}
</style>