<template lang="pug">
.day-selector-box
  .flex.justify-between.items-center
    .date {{ daySelected }}
    .selectors
      svgIcon.selector(
        :path="mdiChevronLeft",
        type="mdi",
        :size="24",
        @click="prevDay"
      )
      svgIcon.selector(
        :path="mdiChevronRight",
        type="mdi",
        :size="24",
        @click="nextDay"
      )
</template>
<script>
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "box-selector-day",
  data: () => ({
    mdiChevronLeft,
    mdiChevronRight,
  }),

  setup() {
    const store = useStore();
    const nameDays = [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ];
    const nameMonths = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const daySelected = computed(() => {
      const day = store.getters["calendarStore/selectedDay"];
      console.log(day.getDay());
      return `${
        nameDays[day.getDay() == 0 ? 7 - 1 : day.getDay() - 1]
      }, ${day.getDate()} ${nameMonths[day.getMonth()]}`;
    });

    const prevDay = () => store.dispatch("calendarStore/subDay");
    const nextDay = () => store.dispatch("calendarStore/sumDay");

    return {
      prevDay,
      nextDay,
      daySelected,
    };
  },
};
</script>
<style lang="scss" scoped>
.day-selector-box {
  @apply ml-8 px-8 py-8 bg-gradient-to-r from-blue-500 to-blue-300 text-white shadow-xl relative;

  .date {
    user-select: none;
    @apply text-xl font-normal;
  }
  .selectors {
    @apply flex items-center;
    .selector {
      @apply cursor-pointer;
      & + .selector {
        @apply ml-4;
      }

      &:hover {
        @apply rounded-full bg-blue-300;
      }
    }
  }
}
</style>