<template lang="pug">
.grid.grid-cols-3
  .col-span-1.box-1
    boxWeek
  .col-span-2.box-2
    boxWeekProgress
.grid.grid-cols-3
  .col-span-1
    boxSelectorDay
  .col-span-2
    boxDayMacros
boxDaysWeek
boxReceipts
</template>
<script>
import boxWeek from "@/components/dashboard/principal/box-week.vue";
import boxWeekProgress from "@/components/dashboard/principal/box-week-progress.vue";
import boxSelectorDay from "@/components/dashboard/principal/box-selector-day.vue";
import boxDayMacros from "@/components/dashboard/principal/box-day-macros.vue";
import boxDaysWeek from "@/components/dashboard/principal/box-days-week.vue";
import boxReceipts from "@/components/dashboard/principal/box-receipts.vue";

export default {
  name: "dashboard.principal",
  components: {
    boxWeek,
    boxWeekProgress,
    boxSelectorDay,
    boxDayMacros,
    boxDaysWeek,
    boxReceipts,
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.box-1,
.box-2 {
  @apply px-8 py-9 border-b-2 border-r-2 border-gray-100;
}
</style>