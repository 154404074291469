<template lang="pug">
.grid.grid-cols-7.grid-week
  .box-day(
    v-for="(date, index) in daysWeek",
    :key="index",
    v-bind:class="{ selected: date.getDate() == selectedDay.getDate() }"
  )
    .num {{ date.getDate() }}
    .day-name {{ nameDays[index] }}
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "box-days-week",
  data: () => ({
    nameDays: [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ],
  }),
  setup() {
    const store = new useStore();

    const selectedDay = computed(
      () => store.getters["calendarStore/selectedDay"]
    );

    const daysWeek = computed(
      () => store.getters["calendarStore/completeWeek"]
    );

    return { selectedDay, daysWeek };
  },
};
</script>
<style lang="scss" scoped>
.grid-week {
  user-select: none;

  margin-top: -2px;
  @apply border-2 px-8 py-6 mx-8 border-gray-100;

  .box-day {
    @apply flex flex-col items-center justify-center text-gray-400;
    .num {
      @apply font-medium text-2xl;
    }
    .day-name {
      @apply font-medium;
    }

    &.selected {
      @apply text-blue-600;
      .num,
      .day-name {
        @apply font-bold;
      }
    }
  }
}
</style>