<template lang="pug">
.box-progress
  h1 Progresos de la semana
  .grid.grid-rows-4.gap-2
    BarCharts(
      color="bg-blue-500",
      name="Kcal",
      :percent="macrosPercent.kcal",
      :current="`${macrosConsumed.kcal.toFixed(2)}kcal`"
    )
    BarCharts(
      color="bg-red-500",
      name="Grasa",
      :percent="macrosPercent.fats",
      :current="`${macrosConsumed.fats.toFixed(2)}gr`"
    )
    BarCharts(
      color="bg-yellow-500",
      name="Carbohidrato",
      :percent="macrosPercent.carb",
      :current="`${macrosConsumed.carb.toFixed(2)}gr`"
    )
    BarCharts(
      color="bg-green-500",
      name="Proteina",
      :percent="macrosPercent.protein",
      :current="`${macrosConsumed.protein.toFixed(2)}gr`"
    )
</template>
<script>
import BarCharts from "@/components/dashboard/bar.chart";
import { calculatePercent } from "@/app/composables/calculatePercent";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "box-week-progress",
  components: {
    BarCharts,
  },
  setup() {
    const store = useStore();
    const macrosWeek = computed(
      () => store.getters["menuStore/getMacrosSelectedWeek"]
    );
    const macrosConsumed = computed(
      () => store.getters["menuStore/getPercentMacrosConsumed"]
    );

    const macrosPercent = computed(() => {
      return {
        fats: calculatePercent(
          macrosWeek.value.fats,
          macrosConsumed.value.fats
        ),
        carb: calculatePercent(
          macrosWeek.value.carb,
          macrosConsumed.value.carb
        ),
        protein: calculatePercent(
          macrosWeek.value.protein,
          macrosConsumed.value.protein
        ),
        kcal: calculatePercent(
          macrosWeek.value.kcal,
          macrosConsumed.value.kcal
        ),
      };
    });

    return {
      macrosWeek,
      macrosConsumed,
      macrosPercent,
    };
  },
};
</script>
<style lang="scss" scoped>
.box-progress {
  h1,
  .dates {
    @apply text-xl font-semibold text-gray-400 uppercase pb-4;
  }

  h2 {
    @apply text-4xl font-semibold pb-4 pt-8;
  }
  .dates {
    @apply text-base;
  }
}
</style>