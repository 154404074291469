<template lang="pug">
.grid.grid-cols-6.mt-8.mb-8.mx-8
  .col-span-1.hidden(class="xl:block")
  .col-span-6(class="xl:col-span-4")
    cardReceiptLite(
      v-for="receipt in receipts",
      :key="receipt._id",
      :receipt="receipt"
    )
  .col-span-1.hidden(class="xl:block")
</template>
<script>
import cardReceiptLite from "./card-receipt-lite.vue";
import { useStore } from "vuex";
import { computed, watch } from "vue";
export default {
  name: "box-receipts",
  components: {
    cardReceiptLite,
  },
  setup() {
    const store = useStore();

    const receipts = computed(() => {
      const receipts = store.getters["menuStore/getReceiptsSelectedDay"];
      return receipts;
    });

    return {
      receipts,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>