<template lang="pug">
.box-meal
  .flex.justify-between
    .content
      .meal-type {{ receipt_type }}
      .meal-name {{ receipt_name }}
      .grid.grid-cols-4.items-center.receipt-macros
        .group-macros
          .block
            .macros-title Calories
            .macros-subtitle.text-blue-500 {{ receipt_macros.kcal.toFixed(2) }}kcal
        .group-macros
          .block
            .macros-title Grasas
            .macros-subtitle.text-red-500 {{ receipt_macros.fats.toFixed(2) }}gr
        .group-macros
          .block
            .macros-title Carb.
            .macros-subtitle.text-yellow-500 {{ receipt_macros.carb.toFixed(2) }}gr
        .group-macros
          .block
            .macros-title Proteina
            .macros-subtitle.text-green-500 {{ receipt_macros.protein.toFixed(2) }}gr
      .group-buttons
        buttonDashboard(
          text="Ver Receta",
          @click="buttonShowReceipt",
          color="default"
        )
    img.img(
      :src="`https://res.cloudinary.com/secure-online-managements-s-l/image/upload/w_350,c_scale/${receipt_hero}.png`"
    )
modal(:show="showReceipt", :disable_close="true")
  customReceiptWrapper(:receipt_id="receipt._id")
</template>
<script>
import { ref, provide, toRefs, computed } from "vue";
import customReceiptWrapper from "@/components/modal-receipt/wrappers/custom-receipt-wrapper.vue";
import { identificationReceipt } from "@/app/composables/identificationReceipt";
export default {
  name: "card-receipt-lite",
  components: {
    customReceiptWrapper,
  },
  props: {
    receipt: Object,
  },
  setup(props) {
    const { receipt } = toRefs(props);
    const receipt_name = computed(() => receipt.value.receipt_name);
    const receipt_macros = computed(() => receipt.value.receipt_macros);
    const receipt_hero = computed(() => receipt.value.receipt_heroImage);
    const { receipt_type } = identificationReceipt(receipt.value.receipt_type);
    const showReceipt = ref(false);

    const buttonShowReceipt = () => {
      showReceipt.value = true;
    };
    const buttonCloseReceipt = () => (showReceipt.value = false);
    provide("buttonCloseReceipt", buttonCloseReceipt);
    return {
      showReceipt,
      buttonShowReceipt,
      receipt_name,
      receipt_macros,
      receipt_type,
      receipt_hero,
    };
  },
};
</script>
<style lang="scss" scoped>
.box-meal {
  @apply rounded-lg border-2 border-gray-100 shadow-lg overflow-hidden;
  & + .box-meal {
    @apply mt-8;
  }

  .img {
    object-fit: cover;
    height: 225px;
    width: 30%;
  }

  .content {
    width: 70%;
    @apply p-8;

    .meal-type {
      @apply text-gray-400 font-medium text-base;
    }

    .meal-name {
      @apply text-gray-600 font-bold text-xl truncate;
    }
  }

  .receipt-macros {
    @apply mt-2 mb-4;
    .group-macros {
      @apply flex justify-start;
      .macros-title {
        @apply font-semibold text-sm text-gray-400;
      }
      .macros-subtitle {
        @apply font-semibold text-base;
      }
    }
  }

  .group-buttons {
    @apply flex;
  }
}
</style>