<template lang="pug">
.day-macros
  .grid.grid-cols-5.items-center.h-full
    .group-macros-day
      .macros-title.first Objetivos
    .group-macros-day
      .block
        .macros-title Calories
        .macros-subtitle.text-blue-500 {{ macrosDay?.kcal?.toFixed(2) || 0 }}kcal
    .group-macros-day
      .block
        .macros-title Grasas
        .macros-subtitle.text-red-500 {{ macrosDay?.fats?.toFixed(2) || 0 }}gr
    .group-macros-day
      .block
        .macros-title Carb.
        .macros-subtitle.text-yellow-500 {{ macrosDay?.carb?.toFixed(2) || 0 }}gr
    .group-macros-day
      .block
        .macros-title Proteina
        .macros-subtitle.text-green-500 {{ macrosDay?.protein?.toFixed(2) || 0 }}gr
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "box-day-macros",
  setup() {
    const store = useStore();
    const macrosDay = computed(
      () => store.getters["menuStore/getMacrosSelectedDay"]
    );
    return {
      macrosDay,
    };
  },
};
</script>
<style lang="scss" scoped>
.day-macros {
  user-select: none;

  @apply mr-8 h-full;
  .group-macros-day {
    @apply flex justify-center;
    .macros-title {
      @apply font-semibold text-sm text-gray-400;
      &.first {
        @apply text-lg text-blue-700;
      }
    }
    .macros-subtitle {
      @apply font-semibold text-xl pt-2;
    }
  }
}
</style>