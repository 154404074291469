<template lang="pug">
.flex.items-center
  .name.pr-4 {{ name }}
  .box-charts
    .total(:class="color", :style="`width: ${percent}%`")
    .metrics
      .current {{ current }}
</template>
<script>
export default {
  name: "bar.charts",
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
      default: "bg-green-400",
    },
    percent: {
      type: String,
      default: "0",
    },
    current: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.name {
  min-width: 120px;
  @apply flex-shrink-0 text-sm font-semibold text-gray-500;
}
.box-charts {
  @apply bg-gray-200 w-full h-8 rounded-lg overflow-hidden relative;

  .total {
    @apply h-full;
  }

  .metrics {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
    .current {
      @apply text-sm text-gray-600;
    }
  }
}
</style>